"use client"
import React, { useEffect, useLayoutEffect, useRef } from "react"
import { Canvas } from "@react-three/fiber"
import { useGLTF, OrbitControls, Environment } from "@react-three/drei"
import { motion as motion3d } from "framer-motion-3d"
import { cn } from "@abraxas/ui/lib"
import {
  motion,
  AnimatePresence,
  useMotionValueEvent,
  useScroll,
} from "framer-motion"
import { CASE_GLB_URL } from "./CASE_GLB_URL"
import { Header } from "@/shared/components/Header"
import { ArrowDownOutlined } from "@ant-design/icons"
import { useMedia } from "react-use"

const TheLock = ({ progress }) => {
  const { scene } = useGLTF(CASE_GLB_URL) as any
  // prettier-ignore
  const scale = scaleLg - ((scaleLg - scaleTarget) * progress)
  const rotateY = (0.25 + progress) * Math.PI * 2
  const isTouch = useMedia("(pointer:coarse)  ")
  return (
    <Canvas>
      {/* we disable rotating on mobile cause otherwise you can't scroll.
       maybe we could add a button to enable/disable it on mobile */}
      {!isTouch && (
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          enableRotate={progress === 1}
        />
      )}
      <Environment preset="warehouse" />
      <motion3d.group
        initial={{
          rotateX: 0 * Math.PI,
          rotateY: 0.5 * Math.PI,
          rotateZ: 0 * Math.PI,
          scale: 1.4 * scaleLg,
        }}
        animate={{
          scale,
          rotateY,
        }}
        transition={{
          scale: { duration: 0, ease: "linear" },
          rotateY: { duration: 0, ease: "linear" },
          // rotateY: { duration: 1.5, ease: "linear", repeat: Infinity },
        }}
      >
        <primitive object={scene} />
      </motion3d.group>
    </Canvas>
  )
}

const scaleLg =
  typeof window === "undefined" ? 120 : window.innerWidth > 768 ? 120 : 100
const scaleTarget =
  typeof window === "undefined" ? 60 : window.innerWidth > 768 ? 60 : 40

const HomeLock: React.FC<{ voteBanner: React.ReactNode }> = ({
  voteBanner,
}) => {
  const [progress, setProgress] = React.useState(0)
  const { scrollY } = useScroll()
  const [scrollTarget, setScrollTarget] = React.useState(0)
  const timer = useRef<any>()
  const [showArrow, setShowArrow] = React.useState(false)
  useEffect(() => {
    timer.current = setTimeout(() => {
      setShowArrow(true)
    }, 5000)
    return () => clearTimeout(timer.current)
  }, [])
  useLayoutEffect(() => {
    setScrollTarget(window.innerHeight * 2)
  }, [])

  useMotionValueEvent(scrollY, "change", () => {
    setProgress(Math.min(scrollY.get() / scrollTarget, 1))
    setShowArrow(false)
    if (timer.current) {
      clearTimeout(timer.current)
    }
  })

  const siteEntered = progress === 1

  return (
    <>
      <motion.div
        initial={{
          y: "-100%",
        }}
        animate={{
          y: siteEntered ? 0 : "-100%",
        }}
        className="fixed top-0 w-full left-0 z-50"
      >
        <Header className={cn("transition-transform", "static z-20")} />
      </motion.div>

      <div className={cn("w-screen h-screen sticky top-0")}>
        <div className="bg-gradient-to-t from-brand-dark to-transparent absolute bottom-0 w-full h-1/2" />
        <TheLock progress={progress} />
        <div
          className="absolute bottom-4 left-1/2 -translate-x-1/2 cursor-pointer"
          onClick={() => {
            window.scrollTo({
              top: scrollTarget,
              behavior: "smooth",
            })
          }}
        >
          <ArrowDownOutlined
            className={cn(
              "animate animate-bounce opacity-0 text-white transition-all",
              "text-2xl",
              showArrow ? "opacity-50 cursor-pointer" : "pointer-events-none",
            )}
          />
        </div>
      </div>
      <AnimatePresence mode="wait">
        {siteEntered && (
          <motion.div
            initial={{ opacity: 0, x: "120%" }}
            animate={{ opacity: 1, x: "0%" }}
            exit={{ opacity: 0, x: "120%" }}
            transition={{
              delay: 0.1,
            }}
            className="md:fixed md:bottom-4 md:right-4 z-50"
          >
            {voteBanner}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default HomeLock
