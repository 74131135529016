import { useLocksBalance } from "@/hooks/useLocksBalance"
import { Menu, Popover } from "antd"
import Link from "next/link"
import { useAccount } from "wagmi"
import { LoomlockLogo } from "../LoomlockLogo"
import { Button } from "@abraxas/ui/ui"
import { cn, formatAddress } from "@abraxas/ui/lib"
import { ConnectButton as RBConnectButton } from "@rainbow-me/rainbowkit"
import { useVaults } from "@abraxas/ui/web3"
import { AnimatePresence, motion } from "framer-motion"
import { InfoCircleOutlined } from "@ant-design/icons"
import { Loading } from "./Loading"

const LocksBalance = () => {
  const { address } = useAccount()
  const { data: balance } = useLocksBalance()
  if (!address) {
    return null
  }
  return (
    <div suppressHydrationWarning className="px-4">
      {balance?.toLocaleString()} $LOCKS
    </div>
  )
}

// TODO: design, add explanations, get LOCKs balance, ENS name
// should this be in @abraxas/ui/web3?
const Delegates = () => {
  const { data, error, setOnBehalfOf, onBehalfOf } = useVaults(true)

  return (
    <div className="w-screen max-w-xs">
      <div className="text-center font-bold">Select an account</div>
      <div className="flex justify-between mb-4 mt-2 gap-4 items-center">
        <span className="w-full text-yellow text-xs text-right">
          Delegate to another address
        </span>
        {/* <Button
          icon={<InfoCircleOutlined />}
          color="yellow"
          variant="outlined"
          size="small"
          href="https://docs.bless.net/blessnet-apps/modular-permissions"
          target="_blank"
        >
          Learn more
        </Button> */}
      </div>
      <hr className="hr text-white " />
      {error ? (
        <div className="py-8 00">woops, there was an error</div>
      ) : !data ? (
        <motion.div layoutId="delegates">
          <Loading className="w-16 my-16" />
        </motion.div>
      ) : (
        <motion.ul
          layoutId="delegates"
          className="my-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {data?.asDelegate.length === 0 ? (
            <li>This address is not delegated by any vault</li>
          ) : (
            data?.asDelegate!.map(({ vault }, i) => (
              <li key={vault}>
                <div
                  className={cn(
                    "py-2",
                    "flex justify-between gap-4",
                    onBehalfOf === vault && "font-bold",
                  )}
                >
                  <button type="button" onClick={() => setOnBehalfOf(vault)}>
                    {formatAddress(vault, 20)}
                  </button>
                  {onBehalfOf === vault && (
                    <Button
                      size="small"
                      htmlType="button"
                      onClick={() => setOnBehalfOf(null)}
                    >
                      unlink
                    </Button>
                  )}
                </div>
                {i < data.asDelegate.length - 1 && (
                  <hr className="hr text-brand-light-bg" />
                )}
              </li>
            ))
          )}
        </motion.ul>
      )}
      <hr className="hr text-white mb-4" />
      <div className="text-center">
        <Button
          className="mx-auto"
          variant="outlined"
          onClick={() => setOnBehalfOf(null)}
          icon={<InfoCircleOutlined />}
        >
          Learn more
        </Button>
      </div>
    </div>
  )
}

const ConnectButton = () => {
  const { onBehalfOf } = useVaults()
  return (
    <RBConnectButton.Custom>
      {({ account, openConnectModal, authenticationStatus, mounted }) => {
        const ready = mounted && authenticationStatus !== "loading"
        if (!account) {
          return (
            <Button loading={!ready} onClick={openConnectModal}>
              connect
            </Button>
          )
        }
        return (
          <Popover
            style={{ width: 500, borderRadius: 5, padding: 10 }}
            content={<Delegates />}
            trigger="hover"
          >
            <div className="flex items-end flex-col text-white min-w-[12ch]">
              <AnimatePresence>
                {onBehalfOf && (
                  <motion.div
                    initial={{
                      maxHeight: 0,
                      opacity: 0,
                    }}
                    animate={{
                      maxHeight: "3rem",
                      opacity: 1,
                    }}
                    exit={{
                      maxHeight: 0,
                      opacity: 0,
                    }}
                  >
                    {formatAddress(onBehalfOf)}
                  </motion.div>
                )}
              </AnimatePresence>
              <div
                className={cn(onBehalfOf && "text-[10px]", "transition-all")}
              >
                {onBehalfOf && "via "}
                {account?.ensName || formatAddress(account?.address!)}
              </div>
            </div>
          </Popover>
        )
      }}
    </RBConnectButton.Custom>
  )
}

const menuItems = [
  // { key: "/wadmin/raffle", icon: <DotChartOutlined />, label: "Raffles" },
  // { href: "/wadmin", label: "Admin" },
  // { href: "/votes/submit-your-design", label: "Contest" },
  // { href: "/portfolio", label: "Portfolio" },
  // { href: "/votes", label: "Votes" },
]
export const Header = ({ className }: { className?: string }) => {
  return (
    <header
      className={cn(
        "sticky top-0 w-full z-20 !bg-brand/80 backdrop-blur-lg",
        className,
      )}
    >
      <div className="container flex justify-between items-center gap-4 py-1.5">
        <Link
          href="/#the-lock"
          className="text-white hover:text-brand-ultralight"
        >
          <LoomlockLogo className="h-10" />
        </Link>
        <div className="grow inline-flex justify-end">
          <Menu
            className="!bg-transparent"
            mode="horizontal"
            defaultSelectedKeys={["0"]}
            selectedKeys={["0"]}
            items={menuItems.map(({ href, label }) => ({
              key: href,
              label: <Link href={href}>{label}</Link>,
            }))}
          />
          <div className="flex gap-4 items-center">
            <div className="text-right">
              <div className="text-xl font-bold tracking-wide text-brand-ultralight">
                design contest
              </div>
              <div className="font-semibold -mt-1.5 text-[12px] text-white">
                become a part of our history
              </div>
            </div>
            <Button color="yellow" href="/votes/submit-your-design">
              join
            </Button>
          </div>
          {/* <LocksBalance /> */}
        </div>
        <ConnectButton />
      </div>
    </header>
  )
}
