import { cn } from "@abraxas/ui/lib"
import Image from "next/image"

export const Loading = ({ className }: { className?: string }) => {
  return (
    <Image
      priority
      src="https://lock.nyc3.cdn.digitaloceanspaces.com/assets/loading.gif"
      width="200"
      height="200"
      alt="Loading..."
      className={cn("mx-auto", className)}
    />
  )
}
