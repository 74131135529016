"use client"
import { config, ABILOCK } from "@repo/loomlock"
import {
  useAccount,
  useChainId,
  useReadContract,
  useWatchContractEvent,
} from "wagmi"

export const useLocksBalance = () => {
  const { address } = useAccount()
  const chainId = useChainId()
  const query = useReadContract({
    functionName: "balanceOf",
    address: config[String(chainId)]?.contracts.$lock,
    abi: ABILOCK,
    args: [address as `0x${string}`, 1n],
    query: {
      enabled: Boolean(address),
      select: (data) => Number(data),
    },
  })

  // we don't filter on address because we want to catch both
  // from and to events
  useWatchContractEvent({
    address: config[String(chainId)]?.contracts.$lock,
    abi: ABILOCK,
    eventName: "TransferSingle",
    enabled: Boolean(address),
    onLogs: () => {
      query.refetch()
    },
  })
  useWatchContractEvent({
    address: config[String(chainId)]?.contracts.$lock,
    abi: ABILOCK,
    eventName: "TransferBatch",
    enabled: Boolean(address),
    onLogs: () => {
      query.refetch()
    },
  })
  return query
}
