"use client"
import React, { useEffect, useRef, useLayoutEffect } from "react"
import { Canvas, useLoader, useThree } from "@react-three/fiber"
import { useGLTF, OrbitControls, Environment } from "@react-three/drei"
import { TextureLoader } from "three/src/loaders/TextureLoader.js"

import { motion } from "framer-motion-3d"
import * as THREE from "three"
import { cn } from "@abraxas/ui/lib"

interface STLModelProps {
  texture?: string
  className?: string
  children?: React.ReactNode
  enableRotate?: boolean
  gl?: any
  roughness?: number
  metalness?: number
  autoRotate?: boolean
}

const GLBURL =
  "https://lock.nyc3.cdn.digitaloceanspaces.com/assets/3d/Loomlock_v6.glb"
const blueLoomlock =
  "https://lock.nyc3.cdn.digitaloceanspaces.com/assets/3d/texture-blue.png"

const LockCamera = () => {
  const { camera } = useThree()
  useLayoutEffect(() => {
    camera.position.set(0, 2, 5)
  }, [camera])
  return null
}

const Lock = ({
  texture,
  roughness = 0.3,
  metalness = 0,
  autoRotate,
}: {
  texture: THREE.Texture
  roughness?: number
  metalness?: number
  autoRotate?: boolean
}) => {
  const { nodes, materials } = useGLTF(GLBURL) as any

  const lock = useRef<any>(null)
  const shackle = useRef<any>(null)
  const knobs = useRef<any>(null)
  const group = useRef<any>(null)

  const [rotationTrigger, setRotationTrigger] = React.useState(0)

  useEffect(() => {
    if (!lock.current || !texture) {
      return
    }
    setRotationTrigger((prev) => prev + 1)

    const timer = setTimeout(() => {
      if (lock.current) {
        lock.current.material.map = texture
      }
    }, 200)

    return () => clearTimeout(timer)
  }, [texture])

  useEffect(() => {
    if (!lock.current) {
      return
    }

    setRotationTrigger((prev) => prev + 1)

    const timer = setTimeout(() => {
      if (lock.current) {
        lock.current.material.roughness = roughness
        lock.current.material.metalness = metalness
      }
      if (shackle.current) {
        shackle.current.material.roughness = roughness
        shackle.current.material.metalness = metalness
      }
      if (knobs.current) {
        knobs.current.material.roughness = roughness
        knobs.current.material.metalness = metalness
      }
    }, 200)

    return () => clearTimeout(timer)
  }, [roughness, metalness])

  if (!nodes) {
    return null
  }
  texture.flipY = false
  texture.colorSpace = THREE.SRGBColorSpace
  texture.needsUpdate = true
  return (
    <motion.group
      initial={{
        scale: 30,
        rotateY: 2.7 * Math.PI,
      }}
      animate={{
        rotateX: 0 * Math.PI,
        rotateY: -1.3 * Math.PI + rotationTrigger * 2 * Math.PI,
        rotateZ: 0 * Math.PI,
        scale: 55,
        y: -0.5,
      }}
      transition={{
        rotateY: autoRotate
          ? { duration: 7, ease: "linear", repeat: Infinity }
          : // : { duration: 0.5, type: "spring", stiffness: 200, damping: 8 },
            { duration: 0.3, ease: "easeInOut" },
      }}
    >
      {/* <Bounds fit clip observe margin={10} maxDuration={0}> */}
      <mesh
        ref={lock}
        geometry={nodes.LoomlockOCT2024_2.geometry}
        material={materials.LockTexture}
        // position={position}
      />
      <mesh
        // shackle
        ref={shackle}
        geometry={nodes.LoomlockOCT2024_1.geometry}
        material={materials.LockShackle}
        // position={position}
      />
      <mesh
        // knobs + plug
        ref={knobs}
        geometry={nodes.LoomlockOCT2024_3.geometry}
        material={materials.LockMetal}
        // position={position}
      />
      {/* </Bounds> */}
    </motion.group>
  )
}

export const Lock3D = React.forwardRef<HTMLCanvasElement, STLModelProps>(
  (
    {
      texture,
      enableRotate = true,
      gl,
      roughness,
      metalness,
      children,
      className,
      autoRotate,
    },
    ref,
  ) => {
    const tex = useLoader(TextureLoader, texture || blueLoomlock)
    return (
      <div className={cn("w-full h-full", className)}>
        <Canvas ref={ref} gl={gl}>
          <OrbitControls
            enableZoom={false}
            enablePan={false}
            enableRotate={enableRotate}
          />
          <Environment preset="warehouse" />
          <LockCamera />
          {/* <axesHelper /> */}
          <Lock
            texture={tex}
            roughness={roughness}
            metalness={metalness}
            autoRotate={autoRotate}
          />
          {children}
        </Canvas>
      </div>
    )
  },
)
Lock3D.displayName = "Lock3D"
export default Lock3D
