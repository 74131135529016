"use client"
import Link from "next/link"
import { LoomlockLogoFull } from "@/shared/LoomlockLogo"
import { useEffect } from "react"

type FooterLink = {
  href: string
  label: string
}

type SocialLink = {
  href: string
  icon: JSX.Element
}

const wassiesBywassies: FooterLink[] = [
  { href: "/about", label: "About Us" },
  { href: "/contact", label: "Contact Us" },
  { href: "/journal", label: "Journal" },
]

const supportLinks: FooterLink[] = [
  { href: "/manual", label: "User Manual" },
  { href: "/faq", label: "FAQ" },
  { href: "/privacy", label: "Privacy Policy" },
  { href: "/terms", label: "Terms & Conditions" },
]

const socialLinks: SocialLink[] = [
  {
    href: "https://twitter.com/loomlocknft",
    icon: (
      <svg
        className="w-6 h-6"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    href: "https://t.me/loomlockupdates",
    icon: (
      <svg
        className="w-6 h-6"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.295-.6.295-.002 0-.003 0-.005 0l.213-3.054 5.56-5.022c.24-.213-.054-.334-.373-.121l-6.869 4.326-2.96-.924c-.64-.203-.654-.64.135-.954l11.566-4.458c.538-.196 1.006.128.832.941z" />
      </svg>
    ),
  },
  // {
  //   href: "https://discord.com",
  //   icon: (
  //     <svg
  //       className="w-6 h-6"
  //       fill="currentColor"
  //       viewBox="0 0 24 24"
  //       aria-hidden="true"
  //     >
  //       <path d="M20.317 4.37a19.791 19.791 0 00-4.885-1.515.074.074 0 00-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 00-5.487 0 12.64 12.64 0 00-.617-1.25.077.077 0 00-.079-.037A19.736 19.736 0 003.677 4.37a.07.07 0 00-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 00.031.057 19.9 19.9 0 005.993 3.03.078.078 0 00.084-.028c.462-.63.874-1.295 1.226-1.994a.076.076 0 00-.041-.106 13.107 13.107 0 01-1.872-.892.077.077 0 01-.008-.128 10.2 10.2 0 00.372-.292.074.074 0 01.077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 01.078.01c.12.098.246.198.373.292a.077.077 0 01-.006.127 12.299 12.299 0 01-1.873.892.077.077 0 00-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 00.084.028 19.839 19.839 0 006.002-3.03.077.077 0 00.032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 00-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z" />
  //     </svg>
  //   ),
  // },
  // {
  //   href: "https://instagram.com",
  //   icon: (
  //     <svg
  //       className="w-6 h-6"
  //       fill="currentColor"
  //       viewBox="0 0 24 24"
  //       aria-hidden="true"
  //     >
  //       <path
  //         fillRule="evenodd"
  //         d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   ),
  // },
]

const FooterColumn = ({
  title,
  links,
}: {
  title: string
  links: FooterLink[]
}) => (
  <div className="mb-6 md:mb-0">
    <h2 className="text-lg font-semibold text-brand mb-4">{title}</h2>
    <ul className="space-y-1">
      {links.map((link) => (
        <li key={link.href}>
          <Link href={link.href} className="text-brand-light hover:underline">
            {link.label}
          </Link>
        </li>
      ))}
    </ul>
  </div>
)

const SocialLinks = () => (
  <div className="flex space-x-4">
    {socialLinks.map((link) => (
      <Link
        key={link.href}
        href={link.href}
        className="text-brand-light hover:text-brand-light"
      >
        {link.icon}
      </Link>
    ))}
  </div>
)

export default function Footer() {
  useEffect(() => {
    // setting overscroll color to match the footer's white
    const setBgColor = () => {
      if (!document.body.parentElement) {
        return
      }
      if (window.scrollY > 100) {
        document.body.parentElement!.style.backgroundColor = "#FFFFFF"
      } else {
        document.body.parentElement!.style.backgroundColor = "var(--brand)"
      }
    }
    document.addEventListener("scroll", setBgColor)
    return () => document.removeEventListener("scroll", setBgColor)
  }, [])
  return (
    <footer className="bg-brand w-full z-10">
      <div className="bg-white rounded-top-lg p-8 mx-auto lg:p-16 md:rounded-t-[120px] rounded-t-4xl">
        <div className=" mx-auto flex flex-wrap justify-between lg:justify-between items-start container">
          <Link href="/" className="w-full mb-6 sm:mb-0 lg:w-auto grow">
            <LoomlockLogoFull className="max-h-16 md:max-h-none md:w-auto md:h-20 text-brand" />
          </Link>
          <div className="flex flex-col md:grid lg:grow gap-6 grid-cols-3 justify-end">
            {/* <FooterColumn title="loomlock" links={wassiesBywassies} /> */}
            {/* <FooterColumn title="Support" links={supportLinks} /> */}
            <div className="col-start-3">
              {/* <h2 className="text-lg font-semibold text-brand mb-4">
                Get in touch
              </h2>
              <a
                href="mailto:info@loomlock.com"
                className="text-gray-600 mb-8 block"
              >
                Email us
              </a> */}
              <h2 className="text-lg font-semibold text-brand mb-4">
                Follow us
              </h2>
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
