"use client"
import { cn } from "@abraxas/ui/lib"
import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import { motion, AnimatePresence } from "framer-motion"

const items = [
  {
    img: "https://lock.nyc3.cdn.digitaloceanspaces.com/assets/lock-em/sweets.webp",
    title: "Candy",
    desc: (
      <>
        Help limit your daily candy allowance{" "}
        <span className="font-bold">
          so that sweet tooth doesn’t turn into a lyposuction booth.
        </span>
        <br />
        &nbsp;
      </>
    ),
  },
  {
    img: "https://lock.nyc3.cdn.digitaloceanspaces.com/assets/lock-em/booze.webp",
    title: "Alcohol",
    desc: (
      <>
        We all love a drink. However, drunk you probably likes a drink much more
        than sober you. Close the tap on yourself when you’re sober,{" "}
        <span className="font-bold">so drunk you can’t make bad choices.</span>
      </>
    ),
  },
  {
    img: "https://lock.nyc3.cdn.digitaloceanspaces.com/assets/lock-em/nicotine.webp",
    title: "Nicotine ",
    desc: (
      <>
        Nicotine, while being an incredibly potent nootropic, is also one of the
        most addictive drugs.{" "}
        <span className="font-bold">
          Let the loomlock ensure you limit your consumption.
        </span>
      </>
    ),
  },
  {
    img: "https://lock.nyc3.cdn.digitaloceanspaces.com/assets/lock-em/phone.webp",
    title: "Smart Phone",
    desc: (
      <>
        Emails. Messages. Instagram. Tiktok. Let the loomlock keep these
        distractions at bay{" "}
        <span className="font-bold">so you can finally rest</span>. You're so
        sleepy...
        <br />
        &nbsp;
      </>
    ),
  },
  {
    img: "https://lock.nyc3.cdn.digitaloceanspaces.com/assets/lock-em/controller.webp",
    title: "Games Controllers",
    desc: (
      <>
        The appeal of *just another match* can end up ruining an entire day of
        productive work. There’s a time to play and a time to work.{" "}
        <span className="font-bold">
          Let the loomlock help make this choice much easier.
        </span>
      </>
    ),
  },
]

const getSlidesToShow = () => {
  if (typeof window === "undefined") {
    return 3
  }
  return window.innerWidth > 1200 ? 3 : window.innerWidth > 768 ? 3 : 1
}
export function UsecasesCarousel() {
  const [centerIndex, setCenterIndex] = useState(0)
  const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow())
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow,
    speed: 500,
    arrows: false,
    beforeChange: (_, next) => {
      setCenterIndex(next)
    },
  }

  useEffect(() => {
    const recompute = () => {
      setSlidesToShow(getSlidesToShow())
    }
    recompute()
    window.addEventListener("resize", recompute)
    return () => window.removeEventListener("resize", recompute)
  }, [])

  const slick = useRef<any>(null)
  return (
    <div className="max-w-screen overflow-x-hidden">
      <div className="slider-container w-full container bg-l text-center !px-2  !py-68">
        <div>
          <h2
            className={cn(
              "text-5xl font-bold tracking-normal leading-none sm:text-7xl xl:text-title-2 mb-4",
              "text-brand-ultralight",
            )}
          >
            lock it<span className="hidden md:inline"> all</span> up!
          </h2>
          <div className="text-subtitle-3 font-semibold -mt-4 mb-2 md:mb-0">
            with loomlock
          </div>
        </div>
        <style jsx>
          {`
            .slider-container {
              --l-size: 30%;
            }
            .slider-container :global(.slick-list) {
              overflow: visible;
              margin-left: 2rem;
              margin-right: 2rem;
            }
            .slider-container :global(.slick-slide) {
              transition: all 0.3s ease-in-out;
              opacity: 0.5;
            }

            .slider-container :global(.slick-current) {
              transform: scale(1.3);
              opacity: 1;
            }
          `}
        </style>
        <Slider
          {...settings}
          ref={slick}
          className="py-6 md:py-8"
          key={slidesToShow}
        >
          {items.map(({ img, title }, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className="lg:p-4 cursor-pointer"
              onClick={({ currentTarget }) => {
                const tgt = parseInt(
                  currentTarget.parentElement!.parentElement!.attributes[
                    "data-index"
                  ].value,
                  10,
                )
                slick.current.slickGoTo(tgt)
              }}
            >
              <img className="pointer-events-none " src={img} alt={title} />
            </div>
          ))}
        </Slider>
        <div>
          <AnimatePresence initial={false} mode="wait">
            {items.map(
              ({ title, desc }, index) =>
                index === centerIndex && (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <motion.div
                      className="text-yellow font-bold"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 20 }}
                      transition={{ duration: 0.3 }}
                    >
                      {title}
                    </motion.div>

                    <motion.p
                      className="max-w-[50ch] mx-auto"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 20 }}
                      transition={{ duration: 0.3, delay: 0.1 }}
                    >
                      {desc}
                    </motion.p>
                  </React.Fragment>
                ),
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}
